html,
body {
  font-family: "Roboto", sans-serif;
  padding: 0;
  margin: 0;
}

@media (min-width: 480px) {
  #root {
    height: 100vh;
  }
}

* {
  box-sizing: border-box;
}

.joinOuterContainer {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  background-color: #1a1a1d;

  .scroll::-webkit-scrollbar {
    display: none;
  }
}

.joinInnerContainer {
  width: 70%;
  margin-bottom: 30px;
}

.joinInput {
  border-radius: 0;
  padding: 15px 20px;
  width: 100%;
}

.heading {
  color: white;
  font-size: 2.5em;
  padding-bottom: 10px;
  border-bottom: 2px solid white;
  padding-top: 30px;
  margin-bottom: 20px;
}

.button {
  color: #fff !important;
  text-transform: uppercase;
  text-decoration: none;
  background: #2979ff;
  padding: 20px;
  border-radius: 5px;
  display: inline-block;
  border: none;
  width: 100%;
}

.mt-20 {
  margin-top: 20px;
}

@media (min-width: 320px) and (max-width: 480px) {
  .joinOuterContainer {
    height: 100%;
  }

  .joinInnerContainer {
    width: 90%;
    height: 10;
  }
}

button:focus {
  outline: 0;
}
