.form {
  display: flex;
  border-top: 1px solid #D3D3D3;
  min-height: 75px;
  max-height: 75px;
  overflow: hidden;
}

.input {
  border: none;
  border-radius: 0;
  padding: 5%;
  width: 80%;
  font-size: 1.2em;
  height: 60px;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

.sendButton {
  color: rgb(116, 116, 116);
  text-transform: uppercase;
  background: white;
  border: none;
}

.loading-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 3px solid #ffc028;
  border-top: 5px solid transparent;
  border-radius: 50%;
  animation: rotate 1s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.ex-box {
  padding: 10px;
  border: 1px solid black;
}