.infoBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffc028;
  border-radius: 4px 4px 0 0;
  width: 100%;
  padding: 10px 0px;
  margin-top: 0px;
}

.leftInnerContainer {
  flex: 0.9;
  margin-left: 5%;
  color: white;
}

.rightInnerContainer {
  display: flex;
  flex: 0.1;
  justify-content: flex-end;
  margin-right: 5%;
}

.onlineIcon {
  margin-right: 5%;
}
