.paddingDiv {
  padding: 0px 0px;
  width: 99vw;
  height: 100vh;
}

.tdStyle {
  border: 1px solid #ddd;
  padding: 15px 5px;
}

.chattinglist {
  color: white;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 60%;
}

.chattinglistDiv {
  border: 1px solid white;
  margin-top: 10px;
  padding: 10px 10px;
  border-radius: 10px;
  max-height: 80vh;
}

.chattinglistDate {
  color: white;
}

.chattinglistDiv-box::-webkit-scrollbar {
  display: none;
  /* Chrome , Safari , Opera */
}

.chatjoinOuterContainer {
  display: flex;
  justify-content: center;
  text-align: center;
  background-color: rgb(179, 156, 156);
  overflow-y: scroll;
  overflow-x: scroll;
  min-height: 100vh;
}

.chatjoinOuterContainer::-webkit-scrollbar {
  display: none;
  /* Chrome , Safari , Opera */
}

.managerLogin {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: 100vh;
  align-items: center;
  background-color: rgb(179, 156, 156);
}

.googleLogin {
  background-color: wheat;
  border: none;
  padding: 10px;
}
